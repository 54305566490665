import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "accessibility-statement"
    }}>{`Accessibility Statement`}</h1>
    <hr></hr>
    <p>{`This Library App enables users to search the Library catalogue, make reservations, borrow/loan items and renew them. In addition, it provides access to a large number of digital services offered by libraries.`}</p>
    <p>{`This Library App is run by SOLUS UK Ltd, or by our partners and distributors. Please note, Library Apps in the App Stores are marketed for free by the end customer Library Service. We want as many people as possible to be able to use this Library Application. To enable this we have ensured that the Library App is available in different colours and in high contrast with accessible fonts. `}</p>
    <h3> Additionally users can: </h3>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Zoom in up to 300% without the text spilling off the screen`}</li>
      <li parentName="ul">{`Navigate most of the Application using just a keyboard`}</li>
      <li parentName="ul">{`Navigate most of the Application using speech recognition software`}</li>
      <li parentName="ul">{`Listen to most of the Application using a screen reader (including the most recent versions of JAWS, NVDA and VoiceOver). `}<em parentName="li">{`Please note, whilst we have made the Application accessible, we cannot legislate for content added to the Library Application by the end user library service customer, of from third party content systems such as the Integrated Library System or eContent vendors.`}</em></li>
    </ul>
    <h3> How accessible this mobile application is: </h3>
    <hr></hr>
    <p>{`We know that some parts of this Application are not fully accessible:`}</p>
    <ul>
      <li parentName="ul">{`You cannot modify the colour of text or contrast`}</li>
      <li parentName="ul">{`Although standard elements conform to the WCAG 2.1 AAA contrast requirements you cannot modify the line height or spacing of text`}</li>
      <li parentName="ul">{`Any PDF documents linked to from the mobile application may not be fully accessible to screen reader software`}</li>
      <li parentName="ul">{`Live video streams may not have captions`}</li>
      <li parentName="ul">{`Some of our forms are difficult to navigate using just a keyboard. For example, the login form has been referenced as difficult to navigate by a non-sighted user.`}</li>
      <li parentName="ul">{`Some of the webpages that are linked to from the mobile application may not be as accessible as the mobile application itself`}</li>
    </ul>
    <h3> Enforcement procedure: </h3>
    <hr></hr>
    <p>{`The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018 (the ‘accessibility regulations’). If you’re not happy with how we respond to your complaint, contact the Equality Advisory and Support Service (EASS).`}</p>
    <h3> Technical information about this mobile application’s accessibility: </h3>
    <hr></hr>
    <p>{`SOLUS UK Ltd is committed to making its Library Application accessible, in accordance with the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018.`}</p>
    <h3> Compliance status: </h3>
    <hr></hr>
    <p>{`This Application is partially compliant with the Web Content Accessibility Guidelines version 2.1 AA standard, due to the non-compliances and exemptions listed below.`}</p>
    <h3> Non-compliance with the accessibility regulations: </h3>
    <hr></hr>
    <p>{`Some images added by the library may not have a text alternative, so people using a screen reader cannot access the information. This fails WCAG 2.1 success criterion 1.1.1 (non-text content).`}</p>
    <h3> Content that’s not within the scope of the accessibility regulations: </h3>
    <hr></hr>
    <p><strong parentName="p">{`PDFs and other documents`}</strong>{`
Some library information may be provided as a PDF which has been published prior to 23 September 2018.`}</p>
    <p>{`The accessibility regulations do not require us to fix PDFs or other documents published before 23 September 2018 if they’re not essential to providing their services.`}</p>
    <p>{`Any new PDFs or Word documents they publish will meet accessibility standards.`}</p>
    <p><strong parentName="p">{`Live video`}</strong>{`
We do not plan to add captions to live video streams because live video is exempt from meeting the accessibility regulations.`}</p>
    <h3> What we’re doing to improve accessibility: </h3>
    <hr></hr>
    <p>{`SOLUS UK Ltd appointed the Royal National Institute for the Blind in the UK to undertake an accessibility review and report on the Library Application.`}</p>
    <p>{`We received the initial report in July 2022 and have made updates based on that feedback. We are due to have the app re-evaluated prior to the end of 2023. At that point we will then update this accessibility statement to define compliance and any issues mitigated. This applies to the mobile application only and not any external content linked to (for example web pages, video streams or PDFs).`}</p>
    <h3> Preparation of this accessibility statement: </h3>
    <hr></hr>
    <p>{`This statement was prepared on 15/06/2021. It was last updated on 08/08/2023.`}</p>
    <p>{`This Application was last tested in July 2022. The test was carried out by the RNIB Ltd and covered the full user journey on the Library Application from the initial registration and log in, through the more complex elements as they relate to the user library account management functionality and accessing third party services.`}</p>
    <p>{`Following the forthcoming publication of the Royal National Institute for the Blind’s accessibility re-evaluation of the Library Application, we will submit the accessibility test report into this accessibility statement.`}</p>
    <p>{`Reporting accessibility problems with this Application
We’re always looking to improve the accessibility of this Application. If you find any problems not listed on this page or think we’re not meeting accessibility requirements, please contact our support team via email at `}<a parentName="p" {...{
        "href": "mailto:support@sol.us"
      }}>{`support@sol.us`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      